<template>
  <div class="accountLogin">
    <div class="top">
      <van-nav-bar
        title="账号登录"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
      />
    </div>
    <div class="loginBox">
      <van-form
        validate-first
        @failed="onFailed"
        @submit="onSubmit"
      >
        <van-field
          v-model="userName"
          name="userName"
          clearable
          label="账号"
          placeholder="账号"
          :rules="[
            {
              required: true,
              message: '请输入企业邮箱/手机号码',
              trigger: 'onChange',
            },
          ]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          clearable
          label="密码"
          placeholder="密码"
          @click-right-icon="clickRightIcon"
          :rules="[
            { required: true, message: '请输入密码', trigger: 'onChange' },
          ]"
        >
          <template #right-icon>
            <p
              :style="{
                color: '#409EFF',
              }"
              @click="$router.push('/forgotPassword')"
            >
              忘记密码?
            </p>
          </template>
        </van-field>
        <div class="loginButton">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :loading="loading"
            loading-text="登录中..."
          >登录</van-button>
        </div>
      </van-form>
    </div>
    <van-overlay
      :show="loading"
      duration="0"
      class-name="transparentOverLay"
    />

  </div>
</template>

<script>
import { reqLogin } from '@/api/user'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'accountLogin',
  data() {
    return {
      userName: '',
      password: '',
      loading: false
    }
  },
  methods: {
    ...mapMutations('user', ['setTokenInfo']),
    ...mapActions('message', ['getMessgae']),
    // 提交校验错误
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },
    // 提交登录
    async onSubmit(values) {
      this.loading = true
      try {
        const { data } = await reqLogin(values)
        this.setTokenInfo(data.data.access_token)
        this.$toast.success({
          message: '登录成功',
          duration: 500
        })
        if (this.$route.query.backTo && this.$route.query.backTo !== '/404') {
          this.$router.push(this.$route.query.backTo)
        } else {
          this.$router.push('/')
        }
      } catch (error) {
        this.loading = false
        if (error?.response?.data?.status === 'E00010004') {
          this.$toast.fail({
            message: '用户名错误!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00010002') {
          this.$toast.fail({
            message: '密码错误次数过多,请10分钟后再试!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00010001') {
          this.$toast.fail({
            message: '密码错误!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00010003') {
          this.$toast.fail({
            message: '该账号被停用,请联系相关人员!',
            duration: 500
          })
          return
        }

        this.$toast.fail({
          message: '登录失败',
          duration: 500
        })
      }
    },

    clickRightIcon() { }
  }
}
</script>
<style lang="scss" scoped>
.accountLogin {
  ::v-deep {
    .loginBox {
      .loginButton {
        margin-top: 150px;

        .van-button {
          border-radius: 5px;
          height: 36px;
          width: 95%;
          margin: 0 auto;
          background-color: #1990ff;
        }
      }
    }
  }
}
</style>
